import React from "react";

import QRCode from "qrcode.react";
import { useHistory } from "react-router-dom";

const QRModal = ({ url }) => {
  const history = useHistory();
  const cerrarEditar = (e) => {
    //this.setState({show: false})
    history.push(process.env.PUBLIC_URL + "/");
  }

  return (
    <div
      className="modal fade"
      id="qrModalToggle"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content bg-primary">
          <div className="modal-header">
            <h5
              className="modal-title text-white text-weight-bold"
              id="exampleModalLongTitle"
            >
              QR CODE
            </h5>
          </div>
          <div
            className="modal-body text-center text-white"
            style={{ backgroundColor: "#fff" }}
          >
            <QRCode
              size={260}
              value={url.data}
              //imageSettings={{
              //  src: "https://app.casiba.net/intranet/favicon.ico",
              //  x: null,
              //  y: null,
              //  height: 24,
              //  width: 24,
              //  excavate: true,
              //}}
            />
          </div>
          <div className="modal-footer">
            <button
              className="btn btn btn-secondary ml-auto"
              data-dismiss="modal"
              onClick={() => cerrarEditar()}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QRModal;
