/**
 * QR
 * update 08/2022 
 * By Sergio Sam 
 */

import React from 'react';

import { makeStyles } from '@mui/styles';

import Paper from '@mui/material/Paper';
import { Modal, Button, TextField } from '@mui/material';

import Icon from '@mdi/react'
import { mdiQrcode } from '@mdi/js'
import Breadcrumbs from '@mui/material/Breadcrumbs';

import {
  Grid,
  ThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
} from "@mui/material";

import { createTheme } from "@mui/material/styles";

import Link from '@mui/material/Link';
import MaterialTable from 'material-table';

import QRModal from "./QRModal";
import QRread from "./QRread";
import UserService from "../services/user.service";
import { useState, useEffect } from 'react'

let direction = "ltr";


const theme = createTheme(
  adaptV4Theme({
    direction: direction,
    palette: {
      mode: "light",
    },
  })
);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  modal: {
    position: 'absolute',
    width: 400,
    backgroundColor: '#fff',
    border: '2px solid #000',
    boxShadow: 5,
    padding: (2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  iconos: {
    cursor: 'pointer'
  },
  inputMaterial: {
    width: '100%'
  }
}));

export default function Qr(props) {
  const styles = useStyles();
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [url, setUrl] = useState("");

  const [modalInsertar, setModalInsertar] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);
  const [modalQR, setModalQR] = useState(false);
  const [modal2QR, setModal2QR] = useState(false);

  const [consolaSeleccionada, setConsolaSeleccionada] = useState({
    codigo: '',
    descripcion: '',
    data: '',
  })

  const handleChange = e => {
    const { name, value } = e.target;
    setConsolaSeleccionada(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  //mod codigo qr
  const modQr = async () => {
    const response = await UserService.addmodQr(consolaSeleccionada.id, consolaSeleccionada);
    var dataNueva = response.data;
    dataNueva.map(consola => {
      if (consolaSeleccionada.id === consola.id) {
        consola.codigo = consolaSeleccionada.codigo;
        consola.descripcion = consolaSeleccionada.descripcion;
        consola.data = consolaSeleccionada.data;
      }
    })
    setData(dataNueva);
    getQrs();
    abrirCerrarModalEditar();
  }

  //trae lista de codigos qr
  const getQrs = async () => {
    const result = await UserService.getQrs();
    setData(result.data);
  }

  //del codigo qr
  const delQr = async () => {
    const response = await UserService.delQr(consolaSeleccionada.id);
    var data = response.data;
    setData(data.filter(consola => consola.id !== consolaSeleccionada.id));
    getQrs();
    abrirCerrarModalEliminar();
  }

  const abrirCerrarModalEditar = () => {
    setModalEditar(!modalEditar);
  }

  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  }

  const abrirCerrarModalQR = () => {
    setModalQR(!modalQR);
  }

  const abrirCerrarLeerQR = () => {
    setModal2QR(!modal2QR);
  }

  const seleccionarConsola = (consola, caso) => {
    setConsolaSeleccionada(consola);
    (caso === 'Editar') ? abrirCerrarModalEditar() : abrirCerrarModalEliminar()
  }

  const seleccionarQR = (id) => {
    setUrl(id);
    abrirCerrarModalQR()
  }

  const leerQR = (id) => {
    setUrl(id);
    abrirCerrarLeerQR()
  }

  useEffect(() => {

    const GetData = async () => {
      try {
        const result = await UserService.getQrsPilar();
        if (result) {
          setData(result.data);
        } else {
          props.history.push(process.env.PUBLIC_URL + "/login");
        }
      } catch (e) {
        props.history.push(process.env.PUBLIC_URL + "/login");
      }
    }
    GetData();

  }, []);

  const bodyEditar = (
    <div className={styles.modal}>
      <h3>Editar QR</h3>
      <TextField name="codigo" className={styles.inputMaterial} label="Codigo" onChange={handleChange} value={consolaSeleccionada && consolaSeleccionada.codigo} />
      <br />
      <TextField name="descripcion" className={styles.inputMaterial} label="Descripcion" onChange={handleChange} value={consolaSeleccionada && consolaSeleccionada.descripcion} />
      <br />
      <TextField name="data" className={styles.inputMaterial} label="Data" onChange={handleChange} value={consolaSeleccionada && consolaSeleccionada.data} />
      <br />
      <br /><br />
      <div align="right">
        <Button color="primary" onClick={() => modQr()}>Editar</Button>
        <Button onClick={() => abrirCerrarModalEditar()}>Cancelar</Button>
      </div>
    </div>
  )

  const bodyEliminar = (
    <div className={styles.modal}>
      <p>Estás seguro que deseas eliminar el QR <b>{consolaSeleccionada && consolaSeleccionada.nombre}</b> ? </p>
      <div align="right">
        <Button color="secondary" onClick={() => delQr()} >Sí</Button>
        <Button onClick={() => abrirCerrarModalEliminar()}>No</Button>
      </div>
    </div>
  )

  const bodyQR = (
    <div className={styles.modal}>
      <QRModal url={url} />
    </div>
  )

  const body2QR = (
    <div className={styles.modal}>
      <QRread url={url} />
    </div>
  )

  const inicio = () => {
    props.history.push(process.env.PUBLIC_URL + "/")
  }

  return (
    <Paper className={classes.root}>

      <Breadcrumbs aria-label="breadcrumb">
        <Button style={{ color: "#fff", backgroundColor: "#cd561c" }} variant="contained" onClick={() => inicio()}>Inicio</Button>
        <Button style={{ color: "#fff", backgroundColor: "#cd561c" }} variant="contained" onClick={() => leerQR()}>Leer QR</Button>
      </Breadcrumbs>

      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <div style={{ maxWidth: "100%", direction }}>
            <Grid container>
              <Grid item xs={12}>
                <MaterialTable
                  title=""

                  localization={{
                    toolbar: {
                      searchPlaceholder: 'Buscar...'
                    },
                    header: {
                      actions: 'Acciones'
                    },
                  }}
                  columns={[
                    {
                      title: 'Codigo',
                      field: 'codigo',
                    },
                    {
                      title: 'Descripcion',
                      field: 'descripcion',
                    },
                    {
                      title: 'Peso',
                      field: 'peso',
                    },
                  ]}

                  data={data}
                  actions={[
                    {
                      icon: () => <Icon path={mdiQrcode}
                        size={1}
                        horizontal
                        vertical
                        rotate={180}
                        color="#cd561c"
                      />,
                      tooltip: 'Ver QR',
                      onClick: (event, data) => seleccionarQR(data),
                    },
                    {
                      icon: 'edit',
                      tooltip: 'Editar',
                      onClick: (event, data) => seleccionarConsola(data, 'Editar'),
                    },
                    {
                      icon: 'delete',
                      tooltip: 'Eliminar',
                      onClick: (event, data) => seleccionarConsola(data, 'Eliminar'),
                    }
                  ]}
                  options={{
                    headerStyle: {
                      backgroundColor: '#cd561c',
                      color: '#FFF',
                    },
                    search: true,
                    actionsColumnIndex: -1
                  }}
                />

                <Modal
                  open={modalEditar}
                  onClose={abrirCerrarModalEditar}>
                  {bodyEditar}
                </Modal>

                <Modal
                  open={modalEliminar}
                  onClose={abrirCerrarModalEliminar}>
                  {bodyEliminar}
                </Modal>

                <Modal
                  open={modalQR}
                  onClose={abrirCerrarModalQR}>
                  {bodyQR}
                </Modal>

                <Modal
                  open={modal2QR}
                  onClose={abrirCerrarLeerQR}>
                  {body2QR}
                </Modal>

              </Grid>
            </Grid>
          </div>
        </ThemeProvider>
      </StyledEngineProvider>

    </Paper>
  );
}