/**
 * PWA QRApp
 * update 07/2022
 * By Sergio Sam 
 */

import React from "react";
import ReactDOM from 'react-dom/client';

import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
);

serviceWorkerRegistration.register();
reportWebVitals();

