/**
 * QR
 * update 07/2022
 * By Sergio Sam 
 */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { makeStyles, useTheme } from '@mui/styles';
import { mdiFactory, mdiBookMultipleOutline, mdiBookMultiple, mdiPackageVariant, mdiCar, mdiClipboardListOutline, mdiBagChecked, mdiArchiveCogOutline } from '@mdi/js'
import { mdiArchiveCog } from '@mdi/js';
import { mdiQrcode } from '@mdi/js';
import { mdiMicrosoftExcel } from '@mdi/js';

import Grid from '@mui/material/Grid';

import Icon from '@mdi/react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import UserService from "../services/user.service";
import AuthService from "../services/auth.service";

import { ServiceWorkerUpdateListener } from '../ServiceWorkerUpdateListener.js'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  iconos: {
    cursor: 'pointer'
  },
  inputMaterial: {
    width: '100%'
  },

  paper: {
    padding: 1,
    margin: 'auto',
    maxWidth: 500,
    //  border: `3px solid #004F9E`,
    borderRadius: 2,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },

}));

const Home = (props) => {
  const [updateWaiting, setUpdateWaiting] = useState(false);
  const [registration, setRegistration] = useState(null);
  const [swListener, setSwListener] = useState({});

  const [data, setData] = useState([]);
  const [showClienteBoard, setShowClienteBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);

  const [showSuper, setShowSuper] = useState(false);

  useEffect(() => {

    const GetUser = async () => {
      try {
        const user = await AuthService.getCurrentUser();
        if (user) {
          setShowClienteBoard(user.roles.includes("ROLE_USER"));
          setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
        }
      } catch (e) {
        props.history.push(process.env.PUBLIC_URL + "/login");
        //history.push({ pathname: process.env.PUBLIC_URL + '/login', state: { response: '' } });
      }
    }
    GetUser();

    const GetData = async () => {
      try {
        const result = await UserService.getlive();
        if (result) {
          setData(result.data);
        } else {
          props.history.push(process.env.PUBLIC_URL + "/login");
          //history.push({ pathname: process.env.PUBLIC_URL + '/login', state: { response: '' } });
        }
      } catch (e) {
        props.history.push(process.env.PUBLIC_URL + "/login");
        //history.push({ pathname: process.env.PUBLIC_URL + '/login', state: { response: '' } });
      }
    }
    GetData();

    if (process.env.NODE_ENV !== "development") {
      let listener = new ServiceWorkerUpdateListener();
      setSwListener(listener);
      listener.onupdateinstalling = (installingEvent) => {
        console.log("SW installed", installingEvent);
      };
      listener.onupdatewaiting = (waitingEvent) => {
        console.log("New update waiting", waitingEvent);
        setUpdateWaiting(true);
      };
      listener.onupdateready = (event) => {
        console.log("Updateready event");
        window.location.reload();
      };
      navigator.serviceWorker.getRegistration().then((reg) => {
        listener.addRegistration(reg);
        setRegistration(reg);
      });

      return () => listener.removeEventListener(null, null);
    } else {
      //do nothing because no sw in development
    }


  }, []);

  const handleUpdate = () => {
    swListener.skipWaiting(registration.waiting);
  }

  const classes = useStyles();

  return (

    <>
      <sections>
      
      <UpdateWaiting updateWaiting={updateWaiting} handleUpdate={handleUpdate} />

                {showClienteBoard && (
                    <items>
                        <Paper className={classes.paper}>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/qr`} className="nav-link">
                                            <Icon path={mdiQrcode}
                                                title="Codigos QR"
                                                size={2}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#cd561c"
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Codigos QR
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </items>
                )}

                {showClienteBoard && (
                    <items>
                        <Paper className={classes.paper}>
                            <Grid item xs>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Typography gutterBottom variant="subtitle1">
                                        <Link to={`${process.env.PUBLIC_URL}/importar`} className="nav-link">
                                            <Icon path={mdiMicrosoftExcel}
                                                title="Importar"
                                                size={2}
                                                horizontal
                                                vertical
                                                rotate={180}
                                                color="#cd561c"
                                            />
                                        </Link>
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Importar CSV
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </items>
                )}

      </sections>
    </>

  );
};

export default Home;

const UpdateWaiting = ({ updateWaiting, handleUpdate }) => {
  if (!updateWaiting) return <></>
  return (
    <Alert variant="filled" severity="error" onClick={handleUpdate}>
      Versión nueva disponible, Pulsa aquí para Actualizar!
    </Alert>
  )
}