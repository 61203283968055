/**
 * QR
 * update 07/2022
 * By Sergio Sam 
 */

import React from 'react';

import { makeStyles, useTheme } from '@mui/styles';

import Paper from '@mui/material/Paper';
import { Button, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Alert from '@mui/material/Alert';

import UserService from "../services/user.service";
import { useState, useEffect } from 'react'
import { useParams } from 'react-router';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { ServiceWorkerUpdateListener } from '../ServiceWorkerUpdateListener.js'

//Validacion del formulario
const validationSchema = yup.object({
    codigo: yup
        .string('Codigo requerido')
        .required('Codigo requerido'),
    descorta: yup
        .string('Descrpcion corta requerida')
        .required('Descrpcion corta requerida'),
    marca: yup
        .string('Marca requerida')
        .required('Marca requerida'),
    modelo: yup
        .string('Modelo requreido ')
        //.min(8, 'Password should be of minimum 8 characters length')
        .required('Modelo requerido'),
    tipo: yup
        .string('Tipo requerido')
        .required('Tipo requerido'),
});

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
    modal: {
        backgroundColor: '#fff',
        border: '2px solid #000',
        boxShadow: 5,
        padding: (2, 4, 3),
    },
    iconos: {
        cursor: 'pointer'
    },
    inputMaterial: {
        width: '100%'
    },
    formControl: {
        margin: 1,
        minWidth: 120,
        width: '100%'
    },
    selectEmpty: {
        marginTop: 2,
    },
    cardHeader: {
        padding: (1, 2),
    },
    list: {
        width: 200,
        height: 230,
        backgroundColor: '#fff',
        overflow: 'auto',
    },
}));

export default function RecursoCon(props) {

    const [updateWaiting, setUpdateWaiting] = useState(false);
    const [registration, setRegistration] = useState(null);
    const [swListener, setSwListener] = useState({});

    //inicializacion de variables y validacion
    var d = new Date();
    var finaldate = d.getFullYear() + "-" + (d.getMonth() + 1).toString().padStart(2, "0") + "-" + (d.getDate()).toString().padStart(2, "0")

    //inicializacion de variables y validacion
    const formik = useFormik({
        initialValues: {
            codigo: '',
            peso: '',
            marca: '',
            modelo: '',
            serie: '',
            descripcion: '',
            imei: '',
            so: '',
            memoria: '',
            disco: '',
            certificacion: '',
            cantidad: '',
            tipo: 'Uso Unico Personal',
            idsucursal: '',
            fecha_compra: finaldate,
            data1: '',
            recurso: 'informatica',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values);
        },
    });

    const { id } = useParams();

    const styles = useStyles();
    const classes = useStyles();

    const [checked, setChecked] = useState([]);
    const [data, setData] = useState([]);
    const [datos, setDatos] = useState([]);

    const [datayacimientos, setDatayacimientos] = useState([]);
    const [left, setLeft] = useState([]);
    const [right, setRight] = useState([]);

    const [plantas, setPlantas] = useState([
        { id: 'Mitre', nombre: 'Mitre' },
        { id: 'Sabattini', nombre: 'Sabattini' },
    ]);

    const [tipos, setTipos] = useState([
        { id: 'Uso Unico Personal', nombre: 'Uso Unico Personal' },
        { id: 'Uso Generico Comunitario', nombre: 'Uso Generico Comunitario' },
    ]);

    const peticionPost = async (values) => {
        const response = await UserService.addmodRecurso(id, values);
        cerrarEditar()
    }

    const cerrarEditar = () => {
        props.history.push(process.env.PUBLIC_URL + "/");
    }

    useEffect(() => {

        if (id) {
            const GetData = async () => {
                try {
                    const response = await UserService.getRecursoConPilar(id);

                    if (response) {
                        formik.setValues(response);
                    } else {
                        //  props.history.push(process.env.PUBLIC_URL + "/login");
                    }

                } catch (e) {
                    console.log(e)
                    // props.history.push(process.env.PUBLIC_URL + "/login");
                }
            }
            GetData();
        }

        if (process.env.NODE_ENV !== "development") {
            let listener = new ServiceWorkerUpdateListener();
            setSwListener(listener);
            listener.onupdateinstalling = (installingEvent) => {
                console.log("SW installed", installingEvent);
            };
            listener.onupdatewaiting = (waitingEvent) => {
                console.log("New update waiting", waitingEvent);
                setUpdateWaiting(true);
            };
            listener.onupdateready = (event) => {
                console.log("Updateready event");
                window.location.reload();
            };
            navigator.serviceWorker.getRegistration().then((reg) => {
                listener.addRegistration(reg);
                setRegistration(reg);
            });

            return () => listener.removeEventListener(null, null);
        } else {
            //do nothing because no sw in development
        }


    }, []);

    const handleUpdate = () => {
        swListener.skipWaiting(registration.waiting);
    }

    const inicio = () => {
        props.history.push(process.env.PUBLIC_URL + "/")
    }

    return (


        <Paper className={classes.root}>

            <UpdateWaiting updateWaiting={updateWaiting} handleUpdate={handleUpdate} />

            <Breadcrumbs aria-label="breadcrumb">
            </Breadcrumbs>
            <br></br>

            <div className={styles.modal}>
                <form onSubmit={formik.handleSubmit}>
                    <h3>Consulta de Recurso</h3>

                    <Grid container spacing={3}>

                        <Grid item xs={6}>
                            <TextField
                                inputProps={
                                    { readOnly: true, }
                                }
                                name="codigo"
                                className={styles.inputMaterial}
                                label="Codigo"
                                autoComplete='off'
                                autoFocus={true}
                                value={formik.values.codigo}
                                onChange={formik.handleChange}
                                error={formik.touched.codigo && Boolean(formik.errors.codigo)}
                                helperText={formik.touched.codigo && formik.errors.codigo}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                inputProps={
                                    { readOnly: true, }
                                }
                                name="peso"
                                className={styles.inputMaterial}
                                label="Peso"
                                autoComplete='off'
                                value={formik.values.peso}
                                onChange={formik.handleChange}
                                error={formik.touched.peso && Boolean(formik.errors.peso)}
                                helperText={formik.touched.peso && formik.errors.peso}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                inputProps={
                                    { readOnly: true, }
                                }
                                name="descripcion"
                                className={styles.inputMaterial}
                                label="Descripcion"
                                autoComplete='off'
                                value={formik.values.descripcion}
                                onChange={formik.handleChange}
                                error={formik.touched.descripcion && Boolean(formik.errors.descripcion)}
                                helperText={formik.touched.descripcion && formik.errors.descripcion}
                            />
                        </Grid>

                        <TextField
                            name="data1"
                            type="hidden"
                            value={formik.values.data1}
                        />

                        <TextField
                            name="recurso"
                            type="hidden"
                            value={formik.values.recurso}
                        />

                    </Grid>
                    <br /><br />
                    <div align="right">
                        <Button onClick={() => cerrarEditar()}>Cerrar</Button>
                    </div>
                </form>
            </div>

        </Paper>
    );
    
}

const UpdateWaiting = ({ updateWaiting, handleUpdate }) => {
    if (!updateWaiting) return <></>
    return (
        <Alert variant="filled" severity="error" onClick={handleUpdate}>
            Versión nueva disponible, Pulsa aquí para Actualizar!
        </Alert>
    )
}
