import React , {useState} from "react";

import { Button } from '@mui/material';

import QrReader from 'react-qr-reader';
import { useHistory } from "react-router-dom";

//export default function QRread(props) {
const QRRead = ({ tipo }) => {
    const history = useHistory();

  const [scanResultWebCam] =  useState('');
  
  const handleErrorWebCam = (error) => {
    console.log(error);
  }
  
  const handleScanWebCam = (result) => {
    if (result){
        //setScanResultWebCam(result);
        window.location.href = result;
    }
   }

 const cerrarEditar = () => {
  history.push(process.env.PUBLIC_URL +"/home");
 }

  return (
    <div
      className="modal fade"
      id="qrModalToggle"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content bg-primary">
          <div className="modal-header">
            <h5
              className="modal-title text-white text-weight-bold"
              id="exampleModalLongTitle"
            >
            Lector de codigos QR
            </h5>
          </div>
          <div
            className="modal-body text-center text-white"
            style={{ backgroundColor: "#fff" }}
          >
            {/*  <ReactiveQR onCode={code => console.log(code)} /> */}
            <h3>Presene el codigo QR a la camara</h3>
            <QrReader
            delay={300}
            style={{width: '100%'}}
            onError={handleErrorWebCam}
            onScan={handleScanWebCam}
            />
            <h3>Escaneando codigo... {scanResultWebCam}</h3>
          </div>
          <div className="modal-footer">
            <Button onClick={() => cerrarEditar()}>Cerrar</Button>
          </div>
        </div>
      </div>
    </div>
  );
  }
  export default QRRead;
//};

