import authHeader from "./auth-header";
import { handleResponse } from './handle-response';
import http from "./http-common";

//const API_URL = "http://localhost:8080/api/test/";
const API_URL = 'https://qrapi.industriabasica.net/api/v1/';
const API_URL1 = 'https://qrapi.industriabasica.net/api/';
const user = JSON.parse(localStorage.getItem('user'));

const upload = (id, file, documento, uniqid, onUploadProgress) => {
  let data = new FormData();
  data.append("images", file);
  data.append('email', user.email);
  data.append('id', id);
  data.append('document', documento);
  data.append('uniqid', uniqid);

  return http.post("/uploadfiles", data, {headers: authHeader(),onUploadProgress,});
};

const uploadImport = (id, file, documento, uniqid, onUploadProgress) => {
  let data = new FormData();
  data.append("images", file);
  data.append('email', user.email);
  data.append('id', id);
  data.append('document', documento);
  data.append('uniqid', uniqid);

  return http.post("/uploadfilesImport", data, {headers: authHeader(),onUploadProgress,});
};

//trae datos de un plano
const getPlano= (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'get_plano', requestOptions).then(handleResponse));
};

//trae lista de planos
const getPlanos= () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'get_planos', requestOptions).then(handleResponse));
};

//agreda o modifica un plano
const addmodPlano= (id,row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'addmodplano', requestOptions).then(handleResponse));
};

//elimina un plano
const delPlano= (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'delplano', requestOptions).then(handleResponse));
};

//trae lista de combustible segun id
const getCombustibleid= (id,row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'getcombustibleid', requestOptions).then(handleResponse));
};

//elimina un consumo conbustible
const delCombustible= (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'delcombustible', requestOptions).then(handleResponse));
};

//trae datos de una carga de combustible
const getCombustible= (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'getcombustible', requestOptions).then(handleResponse));
};

//trae lista de combustibles
const getCombustibles= () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'combustibles', requestOptions).then(handleResponse));
};

//agreda o modifica un combustible
const addmodCombustible= (id,row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'addmodcombustible', requestOptions).then(handleResponse));
};

//trae lista de vehiculos segun id
const getVehiculosid= (id,row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'getvehiculosid', requestOptions).then(handleResponse));
};

//trae listado de roles
const getRoles= () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'getRoles', requestOptions).then(handleResponse));
};

//trae estado del vida
const getlive= () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'getlive', requestOptions).then(handleResponse));
};

//trae datos pora el informe
const getInforme= (row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'getinforme', requestOptions).then(handleResponse));
};

/**
 * trae datos pora el informe de recursos 
 * @param {*} row 
 * @returns 
 */
const getInformeRecursos= (row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'get_informe_recursos', requestOptions).then(handleResponse));
};

/**
 * agrega recurso a un empleado 
 * @param {*} id 
 * @param {*} row 
 * @returns 
 */
 const addRecurso= (id,row,recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('recurso', recurso);
  data.append('id_recurso', row.id);
  //data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'add_recurso', requestOptions).then(handleResponse));
};

/**
 * del recurso
 * @param {*} id 
 * @param {*} recurso 
 * @returns 
 */
 const delRecurso= (id,recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('recurso', recurso);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'del_recurso', requestOptions).then(handleResponse));
};

/**
 * desasignar recurso a un empleado 
 * @param {*} id 
 * @param {*} row 
 * @returns 
 */
 const delRecursoSeleccionado= (id,row,recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('recurso', recurso);
  data.append('id_recurso', row.id);
  //data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'del_recurso_seleccionado', requestOptions).then(handleResponse));
};

/**
 * trae datos de un recurso, consultado desde el QR
 * @param {*} id 
 * @returns 
 */
 const getRecursoConPilar= (id) => {
  const data = new FormData();
  //data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL1 + 'get_recurso_con_pilar', requestOptions).then(handleResponse));
};

/**
 * trae datos de un recurso
 * @param {*} id 
 * @returns 
 */
 const getRecurso= (id,recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('recurso', recurso);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'get_recurso_id', requestOptions).then(handleResponse));
};

/**
 * trae recursos 
 * @param {*} id 
 * @param {*} row 
 * @returns 
 */
 const getRecursos= (recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('recurso', recurso);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'get_recursos', requestOptions).then(handleResponse));
};

/**
 * agreda o modifica una recurso 
 * @param {*} id 
 * @param {*} row 
 * @returns 
 */
 const addmodRecurso= (id,row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'addmod_recurso', requestOptions).then(handleResponse));
};

/**
 * trae lista de empleados
 * @returns 
 */
const getEmpleados= () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'get_empleados', requestOptions).then(handleResponse));
};

/**
 * trae datos del empleado
 * @returns 
 */
 const getEmpleado= (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'get_empleado', requestOptions).then(handleResponse));
};

/**
 * trae lista de herramientas aplicadas como recursos de determinado empleado 
 * @param {*} id 
 * @returns 
 */
 const getRecursosNoSeleccionados= (id,recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('recurso', recurso);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'get_recursos_no_seleccionados', requestOptions).then(handleResponse));
};

/**
 * trae lista de herramientas aplicadas como recursos de determinado empleado 
 * @param {*} id 
 * @returns 
 */
 const getRecursosSeleccionados= (id,recurso) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('recurso', recurso);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'get_recursos_seleccionados', requestOptions).then(handleResponse));
};

//trae lista de vehiculos
const getVehiculos= () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'vehiculos', requestOptions).then(handleResponse));
};

//trae datos de un vehiculo
const getVehiculo= (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'getvehiculo', requestOptions).then(handleResponse));
};

//agreda o modifica un vehiculo
const addmodVehiculo= (id,row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'addmodvehiculo', requestOptions).then(handleResponse));
};

//elimina un vehiculo
const delVehiculo= (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'delvehiculo', requestOptions).then(handleResponse));
};

//trae lista de facturas
const getFacturacion= () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'facturacion', requestOptions).then(handleResponse));
};

//trae lista de rendicion
const getRendicion= () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'rendicion', requestOptions).then(handleResponse));
};

//trae lista de remitos pendientes
const getRpendientes= () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'rpendiente', requestOptions).then(handleResponse));
};

//trae lista de refacturacion
const getRefacturacion= () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'refacturacion', requestOptions).then(handleResponse));
};

//trae lista de stock
const getStock= () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'stock', requestOptions).then(handleResponse));
};

//trae lista de areas
const getAreas= () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'getareas', requestOptions).then(handleResponse));
};

//trae datos de una area
const getArea= (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'getarea', requestOptions).then(handleResponse));
};

//agreda o modifica un area
const addmodArea= (id,row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'addmodarea', requestOptions).then(handleResponse));
};

//elimina una area
const delArea= (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'delarea', requestOptions).then(handleResponse));
};

//trae lista de los archivos adjuntos
const getFiles= (id, documento) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('document', documento);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'getfiles', requestOptions).then(handleResponse));
};

//elimina un archivo
const delImage= (id,documento,key) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('key', key);
  data.append('document', documento);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'delimage', requestOptions).then(handleResponse));
};

/*****************************************************************************
 * CODIGOS QR   
 *****************************************************************************/
//consulta el codigo qr por su id
const getQrid= (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'getqrid', requestOptions).then(handleResponse));
};

//consulta lectura codigo qr
const getQrconPilar= (id,tipo) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('tipo', tipo);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL1 + 'getqrconpilar', requestOptions).then(handleResponse));
};

//trae lista de codigos qrs
const getQrsPilar= () => {
  const data = new FormData();
  data.append('email', user.email);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'getqrspilar', requestOptions).then(handleResponse));
};

//agreda o modifica un qr
const addmodQr= (id,row) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);
  data.append('row', JSON.stringify(row));

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'addmodqr_pilar', requestOptions).then(handleResponse));
};

//elimina un qr
const delQr= (id) => {
  const data = new FormData();
  data.append('email', user.email);
  data.append('id', id);

  const requestOptions = { method: 'POST',body:data, headers: authHeader() };
  return (fetch(API_URL + 'delqr', requestOptions).then(handleResponse));
};

/*****************************************************************************
 * FIN CODIGOS QR   
 *****************************************************************************/

export default {
  
  getQrconPilar,  
  getQrsPilar,
  addmodQr,
  getQrid,
  delQr,

  getInformeRecursos,
  getRecursoConPilar,
  getRecursosNoSeleccionados,
  getRecursosSeleccionados,
  delRecursoSeleccionado,
  delRecurso,
  addRecurso,
  getRecurso,
  getRecursos,
  addmodRecurso,

  getEmpleados,
  getEmpleado,

  getPlano,
  getPlanos,
  addmodPlano,
  delPlano,
  getRendicion,
  
  getCombustibleid,
  getCombustible,
  getCombustibles,
  delCombustible,
  addmodCombustible,
  getVehiculosid,
 
  getAreas,
  getArea,
  addmodArea,
  delArea,

  getVehiculos,
  getVehiculo,
  addmodVehiculo,
  delVehiculo,

  getFacturacion,
  getRefacturacion,
  getRpendientes,
  getStock,

  getRoles,
  getlive,
  getInforme,

  upload,
  uploadImport,
  getFiles,
  delImage  
};
