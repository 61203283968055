/**
 * QRApp
 * update 06/2022
 * By Sergio Sam 
 */

import React, { useState, useEffect } from "react";
import clsx from 'clsx';
import { Switch, Route, Link } from "react-router-dom";

import { makeStyles, useTheme } from '@mui/styles';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import Drawer from '@mui/material/Drawer';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Modal from '@mui/material/Modal';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import CssBaseline from '@mui/material/CssBaseline';

import AuthService from "./services/auth.service";
import Login from "./components/Login";
import Register from "./components/Register";
import Home from "./components/Home.component";

import Importar from "./components/Importar.component";

import Profile from "./components/Profile";

import Usuarios from "./components/Usuarios.component";
import UsuariosAdd from "./components/Usuariosadd.component";
import UsuariosMod from "./components/Usuariosmod.component";

import Qr from "./components/Qr.component";
import RecursoCon from "./components/RecursoCon.component";
import Qrcon from "./components/Qrcon.component";
import QRread from "./components/QRread";

import { IntlProvider } from 'react-intl';
import { messages as allMessages } from './messages';
import { useHistory } from "react-router-dom";
import logo from './logob.svg';
import fondo from './fondo.svg';
import './styles.css';

import 'react-toastify/dist/ReactToastify.css';

import UserService from "./services/user.service";

const useStyles = makeStyles((theme) => ({
  pie: {
  },
  root: {
    flexGrow: 1,
    backgroundImage: "url(https://i.imgur.com/HeGEEbu.jpg)"
  },
  menuButton: {
    marginRight: '2px',
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    maxWidth: 900,
  },
  modal: {
    position: 'absolute',
    width: 400,
    border: '2px solid #000',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },

}));

const App = (props) => {
  const history = useHistory();
  const [showClienteBoard, setShowClienteBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);

  const [showSuper, setShowSuper] = useState(false);

  const [currentUser, setCurrentUser] = useState(undefined);
  const [value, setValue] = React.useState(0);
  const theme = useTheme();
  const [url, setUrl] = useState("");
  const [modal2QR, setModal2QR] = useState(false);
  const styles = useStyles();

  const abrirCerrarLeerQR = () => {
    setModal2QR(!modal2QR);
  }

  const leerQR = (id) => {
    setUrl(id);
    abrirCerrarLeerQR()
  }

  const body2QR = (
    <div className={styles.modal}>
      <QRread url={url} />
    </div>
  )

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
      setShowClienteBoard(user.roles.includes("ROLE_USER"));
      setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
    }
  }, []);

  const logOut = () => {
    AuthService.logout();
  };

  // const currentLocale = 'en-US';
  const currentLocale = 'es';
  //const currentLocale = 'de-DE';
  const messages = allMessages[currentLocale];

  const classes = useStyles();
  const [auth, setAuth] = React.useState(true);
  const [opencajon, setOpenCajon] = React.useState(false);
  const [opendrawer, setOpenDrawer] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const opena = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfile = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    AuthService.logout();
    history.push(process.env.PUBLIC_URL + '/login');
    setAnchorEl(null);
  };

  return (

    <IntlProvider locale={currentLocale} messages={messages}>
      <header className={classes.root}>
        <CssBaseline />
        <AppBar style={{ background: '#fff' }} position="static"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar>
            <IconButton
              onClick={handleDrawerOpen}
            >
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              <Link to={`${process.env.PUBLIC_URL}/`} className="nav-link">
                <div className={classes.logo}>
                  <img src={logo} alt="QRApp"></img>
                </div>
              </Link>
            </Typography>
            {currentUser ? (
              <div>
                <IconButton
                  onClick={handleMenu}
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={opena}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleProfile}>
                    {currentUser.username}
                  </MenuItem>
                  <MenuItem onClick={handleLogOut}>Salir</MenuItem>
                </Menu>
              </div>
            ) : (
              <div className="navbar-nav ml-auto">
                <li className="nav-item">
                </li>
                <li className="nav-item">
                </li>
              </div>
            )}
          </Toolbar>
        </AppBar>

        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>

        </Drawer>
      </header>
      <main>
        <Switch>
          <Route exact path={`${process.env.PUBLIC_URL}/recursoconpilar/:id`} component={RecursoCon} />
          <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home} />
          <Route exact path={`${process.env.PUBLIC_URL}/home`} component={Home} />
          <Route exact path={`${process.env.PUBLIC_URL}/importar`} component={Importar} />
          <Route exact path={`${process.env.PUBLIC_URL}/login`} component={Login} />
          <Route exact path={`${process.env.PUBLIC_URL}/register`} component={Register} />
          <Route exact path={`${process.env.PUBLIC_URL}/profile`} component={Profile} />
          <Route exact path={`${process.env.PUBLIC_URL}/usuarios`} component={Usuarios} />
          <Route exact path={`${process.env.PUBLIC_URL}/usuariosadd`} component={UsuariosAdd} />
          <Route exact path={`${process.env.PUBLIC_URL}/usuariosmod/:id`} component={UsuariosMod} />
          <Route exact path={`${process.env.PUBLIC_URL}/qr`} component={Qr} />
          <Route exact path={`${process.env.PUBLIC_URL}/qrconpilar/:id/`} component={Qrcon} />
          <Route path={`${process.env.PUBLIC_URL}/home`} component={Home} />
        </Switch>
      </main>

      <footer>

        <BottomNavigation
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >

          <BottomNavigationAction label="V 1.0" icon={<ChevronLeftIcon />} />

          <BottomNavigationAction label="Leer QR" onClick={() => leerQR()} icon={<ChevronLeftIcon />} />
          <BottomNavigationAction label="." icon={<ChevronLeftIcon />} />

        </BottomNavigation>

      </footer>

      <Modal
        open={modal2QR}
        onClose={abrirCerrarLeerQR}>
        {body2QR}
      </Modal>

    </IntlProvider>
  );
};

export default App;
