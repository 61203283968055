import React from 'react';

import { withStyles, makeStyles, useTheme} from '@mui/styles';

import Paper from '@mui/material/Paper';
import { TableCell, Modal, Button, TextField } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import MaterialTable from 'material-table';

import UserService from "../services/user.service";
import { useState, useEffect } from 'react'

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#004F9E',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  modal: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  iconos: {
    cursor: 'pointer'
  },
  inputMaterial: {
    width: '100%'
  }
}));

export default function Usuarios(props) {
  const styles = useStyles();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [data, setData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [modalInsertar, setModalInsertar] = useState(false);
  const [modalEditar, setModalEditar] = useState(false);
  const [modalEliminar, setModalEliminar] = useState(false);

  const [consolaSeleccionada, setConsolaSeleccionada] = useState({
    nombre: '',
    email: '',
    empresa: '',
    rol: '',
  })

  const handleChange = e => {
    const { name, value } = e.target;
    setConsolaSeleccionada(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const peticionGet = async () => {
    const result = await UserService.getUsuarios();
    setData(result.data);
  }

  const peticionPost = async () => {
    const response = await UserService.getUsuario(consolaSeleccionada.id, consolaSeleccionada);
    setData(data.concat(response.data))
    abrirCerrarModalInsertar()
  }

  const peticionPut = async () => {
    const response = await UserService.getUsuario(consolaSeleccionada.id, consolaSeleccionada);
    var dataNueva = response.data;
    dataNueva.map(consola => {
      if (consolaSeleccionada.id === consola.id) {
        consola.nombre = consolaSeleccionada.nombre;
        consola.email = consolaSeleccionada.email;
        consola.empresa = consolaSeleccionada.empresa;
        consola.rol = consolaSeleccionada.rol;
      }
    })
    setData(dataNueva);
    peticionGet();
    abrirCerrarModalEditar();
  }

  const peticionDelete = async () => {
    const response = await UserService.delUsuario(consolaSeleccionada.id);
    var data = response.data;
    setData(data.filter(consola => consola.id !== consolaSeleccionada.id));
    peticionGet();
    abrirCerrarModalEliminar();
  }

  const abrirCerrarModalInsertar = () => {
    props.history.push(process.env.PUBLIC_URL +"/usuariosadd/")
  }

  const abrirCerrarModalEditar = () => {
    setModalEditar(!modalEditar);
  }

  const abrirCerrarModalEliminar = () => {
    setModalEliminar(!modalEliminar);
  }

  const seleccionarConsola = (consola, caso) => {
    setConsolaSeleccionada(consola);
    (caso === 'Editar') ? props.history.push("/usuariosmod/"+consola.id) : abrirCerrarModalEliminar()
  }

  useEffect(() => {
    const GetData = async () => {
      try {
        const result = await UserService.getUsuarios();
        if (result) {
          setData(result.data);
        } else {
          props.history.push(process.env.PUBLIC_URL +"/login");
          //history.push({ pathname: process.env.PUBLIC_URL + '/login', state: { response: '' } });
        }
      } catch (e) {
        props.history.push(process.env.PUBLIC_URL +"/login");
        //history.push({ pathname: process.env.PUBLIC_URL + '/login', state: { response: '' } });
      }
    }
    GetData();
  }, []);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const bodyInsertar = (
    <div className={styles.modal}>
      <h3>Agregar Nuevo Usuario</h3>
      <TextField name="nombre" className={styles.inputMaterial} label="Nombre" onChange={handleChange} />
      <br />
      <TextField name="descripcion" className={styles.inputMaterial} label="Descripcion" onChange={handleChange} />
      <br />
      <TextField name="nota" className={styles.inputMaterial} label="Nota" onChange={handleChange} />
      <br />
      <TextField name="data" className={styles.inputMaterial} label="Data" onChange={handleChange} />
      <br />
      <br /><br />
      <div align="right">
        <Button color="primary" onClick={() => peticionPost()}>Insertar</Button>
        <Button onClick={() => abrirCerrarModalInsertar()}>Cancelar</Button>
      </div>
    </div>
  )

  const bodyEditar = (
    <div className={styles.modal}>
      <h3>Editar Usuario</h3>
      <TextField name="nombre" className={styles.inputMaterial} label="Nombre" onChange={handleChange} value={consolaSeleccionada && consolaSeleccionada.nombre} />
      <br />
      <TextField name="email" className={styles.inputMaterial} label="Email" onChange={handleChange} value={consolaSeleccionada && consolaSeleccionada.descripcion} />
      <br />
      <TextField name="empresa" className={styles.inputMaterial} label="empresa" onChange={handleChange} value={consolaSeleccionada && consolaSeleccionada.nota} />
      <br />
      <TextField name="rol" className={styles.inputMaterial} label="Rol" onChange={handleChange} value={consolaSeleccionada && consolaSeleccionada.data} />
      <br />
      <br /><br />
      <div align="right">
        <Button color="primary" onClick={() => peticionPut()}>Editar</Button>
        <Button onClick={() => abrirCerrarModalEditar()}>Cancelar</Button>
      </div>
    </div>
  )

  const bodyEliminar = (
    <div className={styles.modal}>
      <p>Estás seguro que deseas eliminar el usuario <b>{consolaSeleccionada && consolaSeleccionada.nombre}</b> ? </p>
      <div align="right">
        <Button color="secondary" onClick={() => peticionDelete()} >Sí</Button>
        <Button onClick={() => abrirCerrarModalEliminar()}>No</Button>
      </div>
    </div>
  )

  return (
    <Paper className={classes.root}>

      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" href="/">
          Inicio
        </Link>
      </Breadcrumbs>

      <br />
      <Button color="primary" variant="contained" onClick={() => abrirCerrarModalInsertar()}>Nuevo Usuario</Button>


      <MaterialTable
        title=""

        localization={{
          toolbar: {
            searchPlaceholder:  'Buscar...' 
          },
          header: {
            actions: 'Acciones'
          },
        }}

        columns={[
          {
            title: 'Nombre',
            field: 'nombre',
          },
          {
            title: 'UP/Almacen',
            field: 'empresa',
          },
          {
            title: 'Rol',
            field: 'rol',
          },
        ]}
        data={data}
        actions={[
          {
            icon: 'edit',
            tooltip: 'Editar',
            onClick: (event, data) =>  seleccionarConsola(data, 'Editar'),
          },
          {
            icon: 'delete',
            tooltip: 'Eliminar',
            onClick: (event, data) => seleccionarConsola(data, 'Eliminar'),
          }
        ]}
        options={{
          headerStyle: {
            backgroundColor: '#004F9E',
            color: '#FFF',
          },
          search: true,
          actionsColumnIndex: -1
        }}
      />

      <Modal
        open={modalInsertar}
        onClose={abrirCerrarModalInsertar}>
        {bodyInsertar}
      </Modal>

      <Modal
        open={modalEditar}
        onClose={abrirCerrarModalEditar}>
        {bodyEditar}
      </Modal>

      <Modal
        open={modalEliminar}
        onClose={abrirCerrarModalEliminar}>
        {bodyEliminar}
      </Modal>
    </Paper>
  );
}