import React from 'react';

import { makeStyles, useTheme} from '@mui/styles';

import Paper from '@mui/material/Paper';
import { Button, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

import UserService from "../services/user.service";
import { useState, useEffect } from 'react'
import { useParams } from 'react-router';
import { useFormik } from 'formik';
import * as yup from 'yup';

//Validacion del formulario
const validationSchema = yup.object({
    nombre: yup
        .string('Nombre del usurio requerido')
        .required('Nombre del usuario requerido'),
    email: yup
        .string('Email del usuario requerido')
        .email('Emeil del usuario requerido')
        .required('Email del usuario requerido'),
    password: yup
        .string('Password requerido')
        .min(8, 'Password debe tener minimo 8 caracteres')
        .required('Password requerido'),
    empresa: yup
        .string('UP/Almacen requerida')
        .required('UP/Almacen requerida'),
    rol: yup
        .string('Rol requerido')
        .required('Rol requerido'),
});


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
    modal: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    iconos: {
        cursor: 'pointer'
    },
    inputMaterial: {
        width: '100%'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export default function Usuariosadd(props) {

    //inicializacion de variables y validacion
    const formik = useFormik({
        initialValues: {
            nombre: '',
            email: '',
            password: '',
            empresa: '',
            rol: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            peticionPost(values);
            //alert(JSON.stringify(values, null, 2));
        },
    });

    const { id } = useParams();

    const styles = useStyles();
    const classes = useStyles();
    const [data, setData] = useState([]);

    const [rol] = useState([
        { id: 'ROLE_USER', nombre: 'Cliente' },
        { id: 'ROLE_ADMIN', nombre: 'Administrador' },
    ]);

    const peticionPost = async (values) => {
        const response = await UserService.addmodUsuario(id, values);
        setData(data.concat(response.data))
        cerrarEditar()
    }

    const cerrarEditar = () => {
        props.history.push(process.env.PUBLIC_URL +"/usuarios");
    }

    useEffect(() => {
        const GetData = async () => {
            try {
                const result = await UserService.getEmpresas();
                if (result) {
                    setData(result.data);
                } else {
                    props.history.push(process.env.PUBLIC_URL +"/login");
                }
            } catch (e) {
                props.history.push(process.env.PUBLIC_URL +"/login");
            }
        }
        GetData();
    }, []);

    return (
        <Paper className={classes.root}>

            <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/">
                    Inicio
                </Link>
                <Link color="inherit" href="/empresas">
                    Usuarios
                </Link>
            </Breadcrumbs>

            <div className={styles.modal}>
                <form onSubmit={formik.handleSubmit}>
                    <h3>Agregar Nuevo Usuario</h3>

                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                name="nombre"
                                className={styles.inputMaterial}
                                label="Nombre"
                                autoFocus={true}
                                required
                                value={formik.values.nombre}
                                onChange={formik.handleChange}
                                error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                                helperText={formik.touched.nombre && formik.errors.nombre}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name="email"
                                className={styles.inputMaterial}
                                label="Email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name="password"
                                className={styles.inputMaterial}
                                label="Password"
                                type="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <InputLabel htmlFor="outlined-age-native-simple">UP/Almacen</InputLabel>
                            <Select
                                native
                                value={formik.values.empresa}
                                onChange={formik.handleChange}
                                error={formik.touched.empresa && Boolean(formik.errors.empresa)}
                                helperText={formik.touched.empresa && formik.errors.empresa}
                                label="UP/Almacen"
                                inputProps={{
                                    name: 'empresa',
                                    id: 'outlined-age-native-simple',
                                }}
                            >
                                <option aria-label="None" value="" />
                                {data.map((value) => (
                                    <option value={value.id} key={value.id}>
                                        {value.nombre}
                                    </option>
                                ))}
                            </Select>
                        </Grid>

                        <Grid item xs={6}>
                            <InputLabel htmlFor="outlined-age-native-simple">Rol</InputLabel>
                            <Select
                                native
                                value={formik.values.rol}
                                onChange={formik.handleChange}
                                error={formik.touched.rol && Boolean(formik.errors.rol)}
                                helperText={formik.touched.rol && formik.errors.rol}
                                label="Rol"
                                inputProps={{
                                    name: 'rol',
                                    id: 'outlined-age-native-simple',
                                }}
                            >
                                <option aria-label="None" value="" />
                                {rol.map((value) => (
                                    <option value={value.id} key={value.id}>
                                        {value.nombre}
                                    </option>
                                ))}
                            </Select>
                        </Grid>

                    </Grid>
                    <br /><br />
                    <div align="right">
                        <Button color="primary" type="submit">Agregar</Button>
                        <Button onClick={() => cerrarEditar()}>Cancelar</Button>

                    </div>
                </form>
            </div>
        </Paper>
    );
}